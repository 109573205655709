import { GetValues } from "../../Config/MyAppConfig";

export const ContentURL = {
    images: {
        AussieFlag: "Flags\\AussieIcon.png",
        AussieFlag1: "Flags\\AussieIcon.png",

        // White checkmark in green circle
        GreenCheckMark: "CheckAndX\\green_circle_check.svg",

        // Black color check mark
        BlackCheck: "CheckAndX/black-tick.svg",

        // Green checkmark without background color
        GreenTick: "CheckAndX\\green_tick.png",
        Calendar: "DateTime\\calendar.svg",
        Clock: "DateTime\\clock.svg",
        ArrowWhite: "Arrows\\arrow_white.svg",


        /**
         * Map marker for the destination address in the Google Maps map.
         */
        MapMarkerDestination: "MapMarker\\map-marker-destination.png",

        // Icons in header and navbar
        headerAndNav: {
            // Home icons orange and black
            HomeIconOrange: "Header\\home-icon.svg",
            HomeIconBlack: "Header\\home-icon-black.svg",
            ScheduleView: "Header/icon-schedule.svg",
            MapView: "Header/icon-map.svg",
            TripHistory: "Header/icon-trip-history.svg",
            PhoneWhite: "Header/phone-white.svg",
            BurgerMenu: "Header/menu_rounded_solid.png",
            FeatureMenu: "Header/whatsnew.svg"
        },

        // Orange color arrow
        ArrowOrange: "Arrows\\arrow-orange.svg",

        // A black coloured X / cross. e.g. the close button in a dialog
        BlackCircleX: "CheckAndX\\x-circle-black.png",

        ErrorExclamation: "CheckAndX\\error-exclamation.png",

        // An orange coloured X / cross. e.g. the close button in a dialog
        OrangeX: "CheckAndX\\orange-x.svg",

        // A red colour X within a circle. example usage in validation/verification errors.
        RedX: "CheckAndX\\x-circle-red.png",

        // A red colour X within a circle. example usage in validation/verification errors.
        RedXCircle: "CheckAndX\\x-circle-red.svg",

        // Loading image
        Loading: "Loading/spinner.gif",

        // Remove trash Image
        RemoveTrash: "HelperIcons/RemoveTrash.svg",

        // Remove trash Grey Image
        RemoveTrashGrey: "HelperIcons/RemoveTrashGrey.svg",

        // green toggle on image
        greenToggleOn: "HelperIcons/greenToggleOn.svg",

        // green toggle off image
        greenToggleOff: "HelperIcons/greenToggleOff.svg",

        // create template image
        createFavourite: "Templates\\fav-add-heart-icon.png",

        vehicles: {
            Any: 'Vehicles\\Any.png',
            Limo: 'Vehicles\\Limo.png',
            Maxi: 'Vehicles\\MAXI.png',
            Sedan: 'Vehicles\\Sedan.png',
            Silver: 'Vehicles\\Silver.png',
            Wheelchair: 'Vehicles\\Wheelchair.png',
            Wagon: 'Vehicles\\Wagon.png',
            Parcel: 'Vehicles\\parcel-delivery.png',
            HeroMaxi: 'Vehicles/vehicle-MAXI.png',
            HeroAny: 'Vehicles/vehicles-next-avaialble.png',
            HeroLimo: 'Vehicles/vehicle-limo.png',
            HeroSedan: 'Vehicles/vehicle-sedan.png',
            HeroSilver: 'Vehicles/vehicle-silver-service.png',
            HeroWheelchair: 'Vehicles/vehicles-wheelchair.png',
            HeroWagon: 'Vehicles/vehicle-wagon.png',
            HeroParcel: 'Vehicles/Parcel-booking-card.png',
            ThingsLogo: 'Vehicles/13things_LOGO_orange.png',
            ParcelInModalActive: 'Vehicles/modal-things-logo-white.png',
            ParcelInModalInactive: 'Vehicles/modal-things-inactive.png'
        },
        Diagnostic: { /*All the diagnostic(error/warning/info/verbose) images can be added within this object* */
            warning: "Diagonsitic/alert-triangle.png",
            WarningCircle: "Diagonsitic/Alert-icon.svg",
            GoodNewsMan: "Diagonsitic/good-news-man.svg",
            ManWithGlass: "Diagonsitic/error-looking.png"
        },

        arrows: {
            arrowRightBlackSolid: 'Arrows\\black-arraw-right.svg',
            arrowRightGreySolid: 'Arrows\\grey-arraw-right.svg',
            arrowUpWhite: 'Arrows\\arrawUpWhite.svg',
            arrowDownWhite: 'Arrows\\arrowDownWhite.svg',
            ArrowUpBlack: 'Arrows/chevron-up.svg',
            arrowRightWhite: "Arrows\\arrowWhiteRight.png",
            arrowRightBlack: "Arrows\\arrowRightBlack.svg",
            ArrowDownBlack: "Arrows\\ArrowDownBlack.svg",
            GreyLeftArrow: "Arrows\\GreyLeftArrow.svg",
            BlackLeftArrow: "Arrows\\BlackLeftArrow.svg"
        },

        buttons: {
            PickerMinus: "Buttons\\minus.svg",
            PickerPlus: "Buttons\\plus.svg",
            greyX: "Buttons\\grey-x.svg",
            addIcon: "Buttons\\add-icon.svg",
            addIconBlack: "Buttons\\add-iconblack.svg"
        },

        address: {
            // "a" image has moved to BrandedContent.ts.
            b: "Address\\dropoff-icon.svg",
            a2b: "Address\\a2b-rainbow.svg",
            PoweredByGoogle: "Address\\powered_by_google.png",
            Connector: "Address/addresses-connector-line.png",
        },

        // Black color X (Close button in some popups)
        BlackX: "CheckAndX\\black-x.png",

        // Black color X (Clear text value)
        BlackClearX: "CheckAndX\\x-clear.png",

        /**
         * Media
         */
        info: {
            blackInfoIcon: 'Info\\blackInfoIcon.svg',
            WhiteInfoIcon: "Info/info_white_round.svg",
            GreyInfoIcon: "Info\\info_grey_round.svg"
        },

        /**
         * Lock
         */
        Lock: {
            HumanIcon: 'Lock\\human-icon.svg',
            PhoneIcon: 'Lock\\phone-icon.svg',
            NumberEdit: 'Lock\\numberEdit.svg',
            OrangeLock: "Lock\\orange-lock.svg",
        },

        /**
         * Assets relating to new login/signup flow using Auth0 API
         */
        Login: {
            AddCreditCard: 'Login\\AddCreditCard.svg',
            MobileVerification: 'Login\\MobileVerification.svg',
            FastBooking: 'Login\\FastBooking.svg',
            PasswordHide: 'Login\\PasswordHide.svg',
            Edit: 'Login\\Edit.svg',
            MessageIconCorrect: 'Login\\error-message-correct.svg',
            MessageIconIncorrect: 'Login\\error-message-incorret.svg',
            MessageIconEmpty: 'Login\\error-message-empty.svg'
        },

        // Images used in Contact Us page
        ContactUs: {
            ManHelping: 'ContactUs/man-help.png',
            OurOfficesOrange: 'ContactUs/icon-our-offices-orange.svg',
            MessageUsWhite: 'ContactUs/icon-message-icon-white.svg',
            MessageUsOrange: "ContactUs/icon-message-icon-orange.svg",
            OurOfficesWhite: 'ContactUs/icon-ouroffices-white.svg',
            HeroImage: 'ContactUs/positional-head-long.jpg',
            SubmitEnquiryInactive: "ContactUs/send_email_gray.svg",
            SubmitEnquiryActive: "ContactUs/send_email_green.svg",
            SubmitEnquiryLoading: "ContactUs/send_email_loading.svg",
            MessageSend: "ContactUs/message-send.png",
            NewMessageSend: "ContactUs/message-send.svg"
        },

        /** Images used in My bookings list */
        MyBookings: {
            ThirteenIcon: 'MyBookings/thirteen.svg',
            Clock: 'MyBookings/icon-time.svg',
            DropoffIcon: 'MyBookings/dropoff-icon.svg',
            DestinationIcon: "MyBookings/dest_icon_old.png",
            ConnectorLine: "MyBookings/connector-line.png",
            PassengerIcon: "MyBookings/passenger-icon1.png",
        },

        /** Images used in cards list */
        PaymentType: {
            WalletIconBlack: "PaymentType/walletIconBlack.svg",
            AddCard: "PaymentType/add_card.svg",
            Selector: {
                Mastercard: "PaymentType/Selector/mastercard.png",
                Discover: "PaymentType/Selector/discover.png",
                Visa: "PaymentType/Selector/visa.png",
                Cabcharge: "PaymentType/Selector/cabcharge.png",
                AmericanExpress: "PaymentType/Selector/amex.png",
                Amex: "PaymentType/Selector/amex.png",
                "Cash/ETFPOS": "PaymentType/Selector/cash.png",
                SATSS: "PaymentType/Selector/tss.png",
                PayPal: "PaymentType/Selector/paypal.png",
                ApplePay: "PaymentType/Selector/ApplePay.png",
                DIGITALPASS: "PaymentType/Selector/cabcharge_digitalpass.png",
                DIGITALFASTCARD: "PaymentType/Selector/cabcharge_digitalfastcard.png",
                GooglePay: "PaymentType/Selector/Gpay.png",
                CreditOrDebit: "PaymentMethod/credit.png",
            }
        },

        /** Images used in user profile */
        UserProfile: {
            MyProfileIcon: "UserProfile/user.svg",
            PhoneBlack: "UserProfile/icon-phone-black.svg",
            PhoneWhite: "UserProfile/phone-white.svg",
            Contact: "UserProfile/contact.svg",
            Star: "UserProfile/star_outline_black.svg",
            Rocket: "UserProfile/rocket-launch-black.svg",
            CircleGrey: "UserProfile/circle-grey.png",
        },

        // Landing page image & text(image)
        LandingPage: {
            LadyImage: "LandingPage\\landingpage-image-lady.jpg",
            //LadyText: "LandingPage\\landingpage-text-lady.svg"
        },

        /**
         * Google map
         */
        Map: {
            Gps: 'Map\\gps.svg',
            Taxi: 'Map\\taxi.png'
        },

        /**
         * Phone
         */
        Phone: {
            CallDriver: 'Phone\\callDriver.png'
        },

        /** Images in What's new cards */
        WhatsNew: {
            // favourites feature image
            favouriteFeature: "WhatsNew/favourites.png",

            // parcel feature image
            parcelFeature: "WhatsNew/13things.png",
            DriverProfile: "WhatsNew/Driver-profile.png",
            MultipleTracking: "WhatsNew/track-trips.png",
            SearchFavourites: "WhatsNew/Search-favourites.png",
            VaccinatedDrivers: "WhatsNew/Vaccinated-Drivers.png",
            WhatIsNew: "WhatsNew/Lighting-icons.svg",
            SaveAddresses: "WhatsNew/Saved_Addresses.svg",
            TripHistory: "WhatsNew/trip-history.png",
            PaymentMethods: "WhatsNew/payment-methods.png",
        },

        /** Images for users */
        Users: {
            Portait: "Users/portrait.svg",
            PortaitToLogin: "Users/login-portrait.svg",
            PortaitToLogout: "Users/logout-portrait.svg"
        },

        // Black color X (Close button in some popups)
        WhiteX: "CheckAndX\\white-x.svg",

        /** Menu item icon */
        Icons: {
            Edit: "Icons/edit.svg"
        },

        /** A white X inside a solid red circle. */
        RedCirleWithX: "CheckAndX/close_red.svg",

        /** A white X inside a solid black circle. */
        BlackCirleWithX: "CheckAndX/close_black.svg",

        /** A white check mark inside a solid green circle. */
        GreenCircleWithCheck: "CheckAndX/check_circle_small.svg",

        ClickAndCollect: {
            DriverNotes: "ClickAndCollect/driver-notes.png",
            Logo: "ClickAndCollect/click-and-collect-logo.png"
        },
        CnpPaymentMethod: {
            PayPal: "PaymentMethod/paypal.png",
            Credit: "PaymentMethod/credit.png",
            Cabcharge: "PaymentMethod/cabcharge.png"
        },

        PayDriverDirect: {
            Lock: "PaymentType/PayDriverDirectInfo/lock.png",
            SecurePayment: "PaymentType/PayDriverDirectInfo/secure_payment.png",
            FasterExit: "PaymentType/PayDriverDirectInfo/faster_exit.png",
        }
    },
    templates: {
        EmailLostProperty: "emailtemplate.html"
    }
};

/** Helper function to cut down a few characters. */
export function getContentUrl(fileName: string): string {
    return GetValues().StaticContentBaseUrl + fileName;
}